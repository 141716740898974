import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Email } from '@/services/services.types';

class EmailService {
    /**
     * Получение всех email
     * @returns {Promise} массив объектов email
     */
    async getAll(): Promise<[CustomError | null, Email[]]> {
        const url = `/emails`;
        const defaultValue = [] as [];
        const errorPath = '[api:email:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение отдельного email
     * @param {String} notification - название уведомления
     * @returns {Promise} объект полученного email
     */
    async getOne(notification: string): Promise<[CustomError | null, Email | null]> {
        const url = `/emails/${notification}`;
        const defaultValue = null;
        const errorPath = '[api:email:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Изменение email
     * @param {String} notification - название уведомления
     * @param {Email} email - объект Email
     */
    async updateOne(notification: string, email: Email): Promise<[CustomError | null, Email | null]> {
        const url = `/emails/${notification}`;
        const defaultValue = null;
        const errorPath = '[api:email:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: email });
    }
}

export default new EmailService();
